import React, { useState, useEffect } from "react";

const Banner = ({ heading, subheading, data }) => {
  const [slide, setSlide] = useState(0);

  const nextSlide = () => {
    setSlide(slide === data.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? data.length - 1 : slide - 1);
  };

  const formatEventNameForUrl = (name) => {
    return name.toLowerCase().replace(/\s+/g, "-");
  };

  const handleBannerClick = (name) => {
    const path = formatEventNameForUrl(name);
    window.location.href = `event-details/${[path]}`;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000); // Change the duration according to your preference (3 seconds = 3000 milliseconds)
    return () => clearInterval(interval);
  }, [slide]);

  return (
    <div className="banner-container">
      <div className="heading-container">
        <div className="heading">
          <h6>{heading}</h6>
        </div>
        <div className="subheading">
          <h6>{subheading}</h6>
        </div>
      </div>
      <div className="carousel-container">
        <div className="carousel">
          {/* <img onClick={prevSlide} className="arrow arrow-left" /> */}
          {/* Check if there's only one item in the data */}
          {data.length === 1 ? (
            <div className="slide" onClick={handleBannerClick(data[0].alt)}>
              <img
                src={data[0].src}
                alt={data[0].alt}
                className="carousel-image"
              />
            </div>
          ) : (
            data.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className={slide === idx ? "slide" : "slide slide-hidden"}
                  onClick={() => handleBannerClick(item.alt)}
                >
                  <img
                    src={item.src}
                    alt={item.alt}
                    className="carousel-image"
                  />
                  {/* <div className="carousel-text">{item.alt}</div> */}
                </div>
              );
            })
          )}
          {/* <img onClick={nextSlide} className="arrow arrow-right" /> */}
          {data.length > 1 && ( // Only show indicators if there's more than one item
            <span className="indicators">
              {data.map((_, idx) => {
                return (
                  <button
                    key={idx}
                    className={
                      slide === idx
                        ? "indicator"
                        : "indicator indicator-inactive"
                    }
                    onClick={() => setSlide(idx)}
                  ></button>
                );
              })}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Banner;
