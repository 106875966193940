import React from "react";
import { useNavigate } from "react-router-dom";

const formatEventNameForUrl = (name) => {
  return name.toLowerCase().replace(/\s+/g, "-");
};

const EventCard = ({ data }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    const U_name = formatEventNameForUrl(data.name);
    window.location.href = `/event-details/${U_name}`;
  };

  // Assuming data.date is an object with the required properties
  const month = data.date ? new Date(data.date).toLocaleString("default", { month: "short" }) : "";
  const day = data.date ? new Date(data.date).getDate() : "";

  return (
    <div className="event-card-container" onClick={handleCardClick}>
      <div className="event-card">
        <img src={data.imageUrl} alt="Card Image" className="card-image" />
        <div className="card-details">
          <div className="date">
            <div>{month}</div>
            <div>{day}</div>
          </div>
          <div className="content">
            <h2 className="heading">
              {data.category} • {data.sub_category}
            </h2>
            <p className="name">{data.name}</p>
            <p className="price">
              {data.ticket_price === 0 ? 'Free' : `Rs. ${data.ticket_price}`}
            </p>
            <div className="location-section">
              <img src={"/icons/map-pin-black.svg"} alt="Location Icon" />
              <p className="location">{data.location}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
