import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { useInputFilter } from "../context/FilterContext";
import { useNavigate } from "react-router-dom";

const SearchForm = () => {
  const [inputSearchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const { inputFilterDispatch } = useInputFilter();
  const navigate = useNavigate();

  const iconsPath = process.env.PUBLIC_URL + "/icons/";

  const handleSearch = async () => {
    try {
      // // Format the date to 'yyyy-MM-dd' without converting to ISO string
      // const formattedDate =
      //   selectedDate !== null && selectedDate !== undefined
      //     ? `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1)
      //         .toString()
      //         .padStart(2, "0")}-${selectedDate
      //         .getDate()
      //         .toString()
      //         .padStart(2, "0")
      //         .toString()}`
      //     : null;
      // setSelectedDate(formattedDate);
      // const response = await axios.get("http://localhost:5000/search", {
      //   params: {
      //     searchTerm: inputSearchTerm,
      //     date: formattedDate, // Use formatted date here
      //   },
      // });
      // const searchData = response.data;
      // console.log("Search results:", searchData);
      const zipCode = "Islamabad"; // TODO: change this when multiple cities are available
      // Dispatch your filter action here if needed
      inputFilterDispatch({
        payload: { inputSearchTerm, zipCode, selectedDate },
      });
      // navigate("/discover");
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="search-container">
      {/* <form onSubmit={handleSearch}> */}
      <div className="search-form">
        <div className="input-container w-1/3">
          <img src={iconsPath + "search.svg"} alt="Search icon" />
          <input
            type="text"
            placeholder="Search by Event, Artist, Venue"
            value={inputSearchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className="input-container w-1/3">
          <img src={iconsPath + "map-pin-blue.svg"} alt="Map pin icon" />
          <input type="text" placeholder="Islamabad" readOnly />
        </div>
        <div className="input-container w-40">
          <div className="date">
            <img src={iconsPath + "calendar-blue.svg"} alt="Calendar icon" />
            <DatePicker
              className="date-picker"
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="yyyy-MM-dd"
              placeholderText="Date"
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        <button
          type="submit"
          className="search-button"
          onClick={handleSearch}
          onKeyDown={handleKeyDown}
        >
          Search
        </button>
      </div>
      {/* </form> */}
    </div>
  );
};

export default SearchForm;
