import React, { useEffect } from "react";
import MapButton from "./MapButton";
import { useNavigate } from "react-router-dom";

const EventDetailsBanner = ({ data, targetId }) => {
  const navigate = useNavigate();

  const handleViewMap = () => {
    window.open(data.location, "_blank");
  };

  const handleRedirect = (url, buttonType) => {
    // Push event data to GTM for button clicks
    window.dataLayer.push({
      event: "event_button_click",
      eventName: data.heading, // Or use data.id for the event ID
      buttonType: buttonType,  // Book Now or Other button type
    });
    window.open(url, "_blank");
  };

  const handleViewDetails = (e) => {
    const section = document.querySelector("#event-details-main");
    section.scrollIntoView({ behavior: "smooth", block: "start" });

    // Track View Details click
    window.dataLayer.push({
      event: "view_details_click",
      eventName: data.heading, // Or use data.id for the event ID
      buttonType: "view_details"
    });
  };

  const isYouTubeLink = (url) => {
    return url.includes("youtube.com") || url.includes("youtu.be");
  };

  return (
    <div className="event-details-banner-container">
      <div
        className="event-details-banner"
        style={{ backgroundImage: `url(${data.backgroundImage})` }}
      >
        {data.backgroundVideo ? (
          isYouTubeLink(data.backgroundVideo) ? (
            <iframe
              // can add width and height attributes if needed
              className="background-video"
              src={data.backgroundVideo}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          ) : (
            <video
              className="background-video"
              autoPlay
              loop
              muted
              playsInline
              src={data.backgroundVideo}
            />
          )
        ) : (
          <></>
        )}
        <div className="left-side">
          <h1 className="heading">{data.heading}</h1>
          <h2 className="subheading">{data.city}</h2>
          <MapButton
            data={{
              text: "View Map",
              onClick: handleViewMap,
            }}
          />
        </div>
        <div className="event-image">
          {data.backgroundVideo ? (
            isYouTubeLink(data.backgroundVideo) ? (
              <iframe
                // can add width and height attributes if needed
                src={data.backgroundVideo}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            ) : (
              <video
                autoPlay
                loop
                muted
                playsInline
                src={data.backgroundVideo}
              />
            )
          ) : (
            <img src={data.backgroundImage} alt="Event" />
          )}
        </div>
        {data.buttonContent1 && (
          <div className="right-side">
            <div className="nested-banner">
              <h3 className="date-time-heading">Date and Time</h3>
              <p className="details">{`${data.date}, ${data.time}`}</p>
              <button
                className="book-now-btn"
                onClick={() => handleRedirect(data.buttonLink1, "Book Now")}
              >
                {data.buttonContent1}
              </button>
              {data.buttonContent2 ? (
                <button
                  className="book-now-btn"
                  onClick={() => handleRedirect(data.buttonLink2, "Other")}
                >
                  {data.buttonContent2}
                </button>
              ) : (
                <a className="view-details-btn" onClick={handleViewDetails}>
                  View Details
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventDetailsBanner;