import React, { useState, useEffect } from "react";
import axios from "axios";
import Footer from "../components/Footer";
import Header from "../components/Header";
import EventBanner from "../components/EventBanner";
import Sidebar from "../components/Sidebar";
import { useFilter, useInputFilter } from "../context/FilterContext";
import CityCards from "../components/CityCards";
import EventCard from "../components/EventCard";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  getItemsByCategory,
  getItemsByLocation,
  getItemsByPriceRange,
  getItemsByPriceSort,
  getItemsByType,
  categorizeEvents,
} from "../utils/Filters";
import LoadingIndicator from "../components/LoadingIndicator";
import { events } from "../db/eventDetailsData";
import LoadingBox from "../components/LoadingBox";

const Discover = () => {
  const locationPath = useLocation();
  const { categoryID } = useParams();
  const [items, setItems] = useState([]);
  const [minPrice, setMinPrice] = useState(Infinity);
  const [maxPrice, setMaxPrice] = useState(-Infinity);
  const [loading, setLoading] = useState(false);
  // const [expandedCategories, setExpandedCategories] = useState({});
  const { sort, type, loc, cat, price } = useFilter();
  const { searchTerm, location, date } = useInputFilter();

  const navigate = useNavigate();
  // const toggleCategoryExpansion = (category) => {
  //   setExpandedCategories((prevState) => ({
  //     ...prevState,
  //     [category]: !prevState[category],
  //   }));
  // };

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setLoading(true);
    const fetchItems = async () => {
      try {
        const checkImageExists = (url) => {
          return new Promise((resolve) => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
          });
        };

        const mapItems = async (data, categorized) => {
          // console.log(data);
          let mappedDataPromises;
          if (categorized) {
            mappedDataPromises = data.map(async (item) => {
              let eventsData = item.events.map(async (eventItem) => {
                const isValidImage = await checkImageExists(
                  eventItem.adv_banner
                );
                const ticketPrices = Object.values(eventItem.ticket_price);
                let minTicketPrice, maxTicketPrice;
                if (ticketPrices.length != 0) {
                  minTicketPrice =
                    Math.min(...ticketPrices) === Infinity
                      ? 0
                      : Math.min(...ticketPrices);
                  maxTicketPrice =
                    Math.max(...ticketPrices) === -Infinity
                      ? 0
                      : Math.max(...ticketPrices);
                } else {
                  minTicketPrice = Infinity;
                  maxTicketPrice = -Infinity;
                }

                // if (maxTicketPrice > maxPrice) {
                //   setMaxPrice(maxTicketPrice);
                // }

                // if (minTicketPrice < minPrice) {
                //   setMinPrice(minTicketPrice);
                // }
                return {
                  id: eventItem.id,
                  type: eventItem.type,
                  category: item.category,
                  sub_category: eventItem.sub_category,
                  location: eventItem.location,
                  city: eventItem.city,
                  name: eventItem.name,
                  ticket_price:
                    minTicketPrice === Infinity ? 0 : minTicketPrice,
                  ticket_prices: ticketPrices.length === 0 ? [0] : ticketPrices,
                  date: new Date(eventItem.date),
                  imageUrl: isValidImage
                    ? eventItem.adv_banner
                    : "../../Adv_imgs/moqah_bg.png",
                };
              });
              let resolvedEventsData = await Promise.all(eventsData);
              return {
                category: item.category,
                events: resolvedEventsData,
              };
            });
            return await Promise.all(mappedDataPromises);
          } else {
            mappedDataPromises = data.map(async (item) => {
              const isValidImage = await checkImageExists(item.adv_banner);

              const ticketPrices = Object.values(item.ticket_price);
              const minTicketPrice =
                Math.min(...ticketPrices) === Infinity
                  ? 0
                  : Math.min(...ticketPrices);
              // const maxTicketPrice =
              //   Math.max(...ticketPrices) === -Infinity
              //     ? 0
              //     : Math.max(...ticketPrices);

              // if (maxTicketPrice > maxPrice) {
              //   setMaxPrice(maxTicketPrice);
              // }

              // if (minTicketPrice < minPrice) {
              //   setMinPrice(minTicketPrice);
              // }

              return {
                id: item.id,
                type: item.type,
                category: item.category,
                sub_category: item.sub_category,
                location: item.location,
                city: item.city,
                name: item.name,
                ticket_price: minTicketPrice,
                ticket_prices: ticketPrices.length === 0 ? [0] : ticketPrices,
                date: new Date(item.date),
                imageUrl: isValidImage
                  ? item.adv_banner
                  : "/Adv_imgs/moqah_bg.png",
              };
            });
            let resolvedPromises = await Promise.all(mappedDataPromises);

            return [{ category: categoryID, events: resolvedPromises }];
          }
        };

        let response, data;
        if (
          (searchTerm !== undefined &&
            searchTerm !== "" &&
            searchTerm !== null) ||
          (location !== undefined && location !== "" && location !== null) ||
          (date !== undefined && date !== "" && date !== null)
        ) {
          // Format the date to 'yyyy-MM-dd' without converting to ISO string
          const formattedDate =
            date !== null && date !== undefined
              ? `${date.getFullYear()}-${(date.getMonth() + 1)
                  .toString()
                  .padStart(2, "0")}-${date
                  .getDate()
                  .toString()
                  .padStart(2, "0")
                  .toString()}`
              : null;
          console.log(`search term:${searchTerm} date:${formattedDate}`);
          response = await axios.get(`${apiUrl}/search`, {
            params: {
              searchTerm: searchTerm,
              date: formattedDate,
              location: location,
            },
          });
          data = await mapItems(response.data.data, false);
          setMaxPrice(response.data.max_price);
          setMinPrice(response.data.min_price);
          setMinPrice(0);
        } else {
          // response = await axios.get(`${apiUrl}/discover`);
          // response = events;
          if (locationPath.pathname === "/discover") {
            // console.log("in the main discover page");
            response = await axios.get(`${apiUrl}/discover`);
            // console.log(response.data);
            data = await mapItems(response.data.data, true);
            // console.log('response from discover  : ',data);
          } else {
            console.log("the category id is", categoryID);
            response = await axios.get(`${apiUrl}/discover/${categoryID}`);
            // console.log(response.data);
            data = await mapItems(response.data.data, false);
            // console.log('response from discover more : ',data);
          }
          console.log(response.data);
          setMaxPrice(response.data.max_price);
          setMinPrice(response.data.min_price);
        }

        // data = await mapItems(response.data);
        setItems(data);
        // console.log(data);
        setLoading(false);
      } catch (error) {
        //console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchItems();
  }, [price, searchTerm, location, date]); // TODO: price should be removed once the backend returns the min and max price

  const filteredProductsByPriceSort = getItemsByPriceSort(items, sort);
  // console.log("the items are");
  const filteredProductsByType = getItemsByType(
    filteredProductsByPriceSort,
    type
  );
  const filteredProductsByLocation = getItemsByLocation(
    filteredProductsByType,
    loc
  );

  const filteredProductsByCategory = getItemsByCategory(
    filteredProductsByLocation,
    cat
  );

  const filteredProductsByPriceRange = getItemsByPriceRange(
    filteredProductsByCategory,
    price
  );
  // const categorizedEvents = categorizeEvents(filteredProductsByPriceRange);
  var categoriesList = items.map((event) => event.category);
  categoriesList = [...new Set(categoriesList)];
  categoriesList = categoriesList.filter((value) => value !== undefined);

  const handleShowMoreClick = (categoryID) => {
    // navigate(`/discover/${categoryID}`);
    window.location.href = `/discover/${categoryID}`;
  };

  const citiesData = [
    {
      name: "Islamabad",
      imgUrl: "islamabad-card.png",
      dest: "",
      active: loc.includes("Islamabad"),
    },
    {
      name: "Lahore",
      imgUrl: "lahore-card.png",
      dest: "",
      active: loc.includes("Lahore"),
    },
    {
      name: "Quetta",
      imgUrl: "quetta-card.png",
      dest: "",
      active: loc.includes("quetta"),
    },
    {
      name: "Karachi",
      imgUrl: "karachi-card.png",
      dest: "",
      active: loc.includes("karachi"),
    },
    {
      name: "Faisalabad",
      imgUrl: "faisalabad-card.png",
      dest: "",
      active: loc.includes("faisalabad"),
    },
    {
      name: "Peshawar",
      imgUrl: "peshawar-card.png",
      dest: "",
      active: loc.includes("peshawar"),
    },
  ];

  const searchTermResults = () => {
    let term = "Search results ";
    if (!searchTerm && !location && !date) {
      return "";
    }
    if (searchTerm) {
      term += `for "${searchTerm}"`;
    }
    if (location) {
      term += ` in ${location}`;
    }
    if (date) {
      term += ` on ${date}`;
    }
    term += ". Please refresh the page if you want to see all events.";
    return term;
  };

  // const filterSummary = () => {
  //   let summary = "Showing results for ";
  //   const filters = [];

  //   if (type) filters.push(`|->type: ${type}`);
  //   if (cat) filters.push(`|->category: ${cat}`);
  //   if (loc && !location) filters.push(`|->location: ${loc.join(" , ")}`);
  //   if (price) filters.push(`|->price: ${price}`);

  //   if (filters.length === 0) {
  //     summary += "all events";
  //   } else {
  //     summary += filters.join(" ");
  //   }

  //   return summary;
  // };

  return (
    // <>
    //   <Header searchBar={true} />
    //   {items.length != 0 ? <EventBanner data={items} /> : <LoadingBox />}
    //   <CityCards data={citiesData} />
    //   <div className="search-term">
    //     <h6>{searchTermResults()}</h6>
    //   </div>
    //   <div className="main-body">
    //     {items.length !== 0 ? (
    //       <Sidebar
    //         minPrice={minPrice}
    //         maxPrice={maxPrice}
    //         categoryFilterOptions={categoriesList}
    //       />
    //     ) : (
    //       <LoadingIndicator />
    //     )}
    //     <div className="content">
    //       {/* <h5>{"<---"}{filterSummary()}{"--->"}</h5> */}
    //       {filteredProductsByPriceRange.length === 0 && loading ? (
    //         <LoadingIndicator />
    //       ) : filteredProductsByPriceRange.length === 0 && !loading ? (
    //         <p className="not-found">
    //           Sorry we are not able to fetch data for the above preferences
    //         </p>
    //       ) : (
    //         categorizedEvents.map((item) => {
    //           const isExpanded = expandedCategories[item.category];
    //           const itemsToShow = isExpanded
    //             ? item.items
    //             : item.items.slice(0, 3);
    //           return (
    //             <div className="event-list-container" key={item.category}>
    //               <div className="category-header">
    //                 <h6>{item.category}</h6>
    //                 <button
    //                   className="see-all-button"
    //                   // onClick={() => toggleCategoryExpansion(item.category)}
    //                   onClick={() => handleShowMoreClick(item.category)}
    //                 >
    //                   {isExpanded ? "Show Less" : "Show More"}
    //                 </button>
    //               </div>
    //               <div className="event-list">
    //                 {itemsToShow.map((eventData) => (
    //                   <EventCard key={eventData.id} data={eventData} />
    //                 ))}
    //               </div>
    //             </div>
    //           );
    //         })
    //       )}
    //     </div>
    //   </div>
    //   <Footer />
    // </>
    <>
      <Header searchBar={true} />
      {/* {items.length != 0 ? <EventBanner data={items} /> : <LoadingBox />} */}
      {categoryID === undefined ? <CityCards data={citiesData} /> : <></>}
      <div className="search-term">
        <h6>{searchTermResults()}</h6>
      </div>
      <div className="main-body">
        {items.length !== 0 ? (
          <Sidebar
            minPrice={minPrice}
            maxPrice={maxPrice}
            categoryFilterOptions={categoriesList}
          />
        ) : (
          <LoadingIndicator />
        )}
        <div className="content">
          {/* <h5>{"<---"}{filterSummary()}{"--->"}</h5> */}
          {
            filteredProductsByPriceRange.length === 0 && loading ? (
              <LoadingIndicator />
            ) : filteredProductsByPriceRange.length === 0 && !loading ? (
              <p className="not-found">
                Sorry we are not able to fetch data for the above preferences
              </p>
            ) : (
              filteredProductsByPriceRange.map((item) => {
                // const isExpanded = expandedCategories[item.category];
                // const itemsToShow = isExpanded
                //   ? item.items
                //   : item.items.slice(0, 3);
                return item.events.length > 0 ? (
                  <div className="event-list-container" key={item.category}>
                    <div className="category-header">
                      <h6>{item.category}</h6>
                      {categoryID === undefined && searchTerm != null ? (
                        <button
                          className="see-all-button"
                          // onClick={() => toggleCategoryExpansion(item.category)}
                          onClick={() => handleShowMoreClick(item.category)}
                        >
                          Show More
                        </button>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <div className="event-list">
                      {item.events.map((eventData) => (
                        <EventCard key={eventData.id} data={eventData} />
                      ))}
                    </div>
                  </div>
                ) : (
                  <div></div>
                );
              })
            )
            // : (
            //   <div className="event-list-container">
            //     <div className="category-header">
            //       <h6>{categoryID}</h6>
            //     </div>
            //     <div className="event-list">
            //       {items.map((eventData) => (
            //         <EventCard key={eventData.id} data={eventData} />
            //       ))}
            //     </div>
            //   </div>
            // )
          }
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Discover;

// useEffect(() => {
//   setLoading(true);
//   const fetchItems = async () => {
//     const checkImageExists = (url) => {
//       return new Promise((resolve) => {
//         const img = new Image();
//         img.src = url;
//         img.onload = () => resolve(true);
//         img.onerror = () => resolve(false);
//       });
//     };

//     const mapItems = async (data, categorized) => {
//       // console.log(data);
//       let mappedDataPromises;
//       if (categorized) {
//         mappedDataPromises = data.map(async (item) => {
//           let eventsData = item.events.map(async (eventItem) => {
//             const isValidImage = await checkImageExists(eventItem.adv_banner);
//             const ticketPrices = Object.values(eventItem.ticket_price);
//             let minTicketPrice, maxTicketPrice;
//             if (ticketPrices.length != 0) {
//               minTicketPrice =
//                 Math.min(...ticketPrices) === Infinity
//                   ? 0
//                   : Math.min(...ticketPrices);
//               maxTicketPrice =
//                 Math.max(...ticketPrices) === -Infinity
//                   ? 0
//                   : Math.max(...ticketPrices);
//             } else {
//               minTicketPrice = Infinity;
//               maxTicketPrice = -Infinity;
//             }

//             if (maxTicketPrice > maxPrice) {
//               setMaxPrice(maxTicketPrice);
//             }

//             if (minTicketPrice < minPrice) {
//               setMinPrice(minTicketPrice);
//             }
//             return {
//               id: eventItem.id,
//               type: eventItem.type,
//               category: item.category,
//               sub_category: eventItem.sub_category,
//               location: eventItem.location,
//               city: eventItem.city,
//               name: eventItem.name,
//               ticket_price: minTicketPrice === Infinity ? 0 : minTicketPrice,
//               ticket_prices: ticketPrices.length === 0 ? [0] : ticketPrices,
//               date: new Date(eventItem.date),
//               imageUrl: isValidImage
//                 ? eventItem.adv_banner
//                 : "../../Adv_imgs/moqah_bg.png",
//             };
//           });
//           let resolvedEventsData = await Promise.all(eventsData);
//           return {
//             category: item.category,
//             events: resolvedEventsData,
//           };
//         });
//         return await Promise.all(mappedDataPromises);
//       } else {
//         mappedDataPromises = data.map(async (item) => {
//           const isValidImage = await checkImageExists(item.adv_banner);

//           const ticketPrices = Object.values(item.ticket_price);
//           const minTicketPrice =
//             Math.min(...ticketPrices) === Infinity
//               ? 0
//               : Math.min(...ticketPrices);
//           // const maxTicketPrice =
//           //   Math.max(...ticketPrices) === -Infinity
//           //     ? 0
//           //     : Math.max(...ticketPrices);

//           // if (maxTicketPrice > maxPrice) {
//           //   setMaxPrice(maxTicketPrice);
//           // }

//           // if (minTicketPrice < minPrice) {
//           //   setMinPrice(minTicketPrice);
//           // }

//           return {
//             id: item.id,
//             type: item.type,
//             category: item.category,
//             sub_category: item.sub_category,
//             location: item.location,
//             city: item.city,
//             name: item.name,
//             ticket_price: minTicketPrice,
//             ticket_prices: ticketPrices.length === 0 ? [0] : ticketPrices,
//             date: new Date(item.date),
//             imageUrl: isValidImage
//               ? item.adv_banner
//               : "/Adv_imgs/moqah_bg.png",
//           };
//         });
//         let resolvedPromises = await Promise.all(mappedDataPromises);
//         return [{ category: categoryID, events: resolvedPromises }];
//       }
//     };

//     try {
//       let response, data;
//       if (
//         (searchTerm !== undefined &&
//           searchTerm !== "" &&
//           searchTerm !== null) ||
//         (location !== undefined && location !== "" && location !== null) ||
//         (date !== undefined && date !== "" && date !== null)
//       ) {
//         response = await axios.get(`${apiUrl}/search`, {
//           params: {
//             searchTerm: searchTerm,
//             date: date,
//             location: location,
//           },
//         });
//       } else {
//         // response = await axios.get(`${apiUrl}/discover`);
//         // response = events;
//         if (locationPath.pathname === "/discover") {
//           console.log("in the main discover page");
//           response = await axios.get(`${apiUrl}/discover`);
//           // console.log(response.data);
//           data = await mapItems(response.data.data, true);
//           // console.log('response from discover  : ',data);
//         } else {
//           console.log("the category id is", categoryID);
//           response = await axios.get(`${apiUrl}/discover/${categoryID}`);
//           // console.log(response.data);
//           data = await mapItems(response.data.data, false);
//           // console.log('response from discover more : ',data);
//         }
//         setMaxPrice(response.data.max_price);
//         setMinPrice(response.data.min_price);
//       }

//       setItems(data);
//       setFilteredItems(data);
//       setLoading(false); // TODO: check if this should be here
//     } catch (e) {
//       setLoading(false);
//       console.error("Error fetching data", e);
//     }
//   };

//   fetchItems();
// }, [categoryID, locationPath.pathname]);

// useEffect(() => {
//   let tempItems = filteredItems;

//   tempItems = getItemsByPriceSort(tempItems, sort);

//   // Apply filters
//   tempItems = getItemsByType(tempItems, type);
//   tempItems = getItemsByLocation(tempItems, loc);
//   tempItems = getItemsByCategory(tempItems, cat);
//   tempItems = getItemsByPriceRange(tempItems, price);

//   setFilteredItems(tempItems);
// }, [items, sort, type, loc, cat, price]);
