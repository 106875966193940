import React, { useEffect, useState } from "react";
import axios from "axios";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Banner from "../components/Banner";
import ButtonList from "../components/ButtonList";
import CreateEventBanner from "../components/CreateEventBanner";
import TrendingCities from "../components/TrendingCities";

const Home = () => {
  const [carouselData, setCarouselData] = useState([]);
  const [trendingCities, setTrendingCities] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  const buttons = [
    { link: "/discover", text: "Islamabad" },
    { link: "/discover", text: "Rawalpindi" },
    { link: "/discover", text: "Lahore" },
    { link: "", text: "Karachi" },
    { link: "", text: "Faisalabad" },
    { link: "", text: "Gujranwala" },
    { link: "", text: "Gujrat" },
    { link: "", text: "Sargodha" },
    { link: "", text: "Peshawar" },
    { link: "", text: "Mardan" },
    { link: "", text: "Hunza" },
    { link: "", text: "Multan" },
  ];

  const cityData = [
    {
      name: "Islamabad",
      headline: "The beautiful capital of Pakistan",
      imageUrl: "islamabad.png",
    },
    {
      name: "Rawalpindi",
      headline: "The Historic Heart of Pakistan",
      imageUrl: "rawalpindi.png",
    },
    { name: "Lahore", headline: "The city of gardens", imageUrl: "lahore.png" },
    {
      name: "Karachi",
      headline: "The city of lights",
      imageUrl: "karachi.png",
    },
    // {
    //   name: "Gujranwala",
    //   headline: "The city of Saand bhai",
    //   imageUrl: "gujranwala.jpeg",
    // },
    // {
    //   name: "Faisalabad",
    //   headline: "The city of idk who",
    //   imageUrl: "faisalabad.jpeg",
    // },
    // {
    //   name: "Peshawar",
    //   headline: "The city of momer",
    //   imageUrl: "peshawar.jpg",
    // },
  ];

  useEffect(() => {
    axios
      .get(`${apiUrl}/trending-events`)
      .then((response) => {
        const checkImageExists = (url) => {
          return new Promise((resolve) => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
          });
        };

        const getTrendingData = async () => {
          const trendingDataPromises = response.data.map(async (item) => {
            const isValidImage = await checkImageExists(item.adv_banner);
            return {
              src: isValidImage ? item.adv_banner : "/Adv_imgs/moqah_bg-01.jpg",
              alt: item.name,
            };
          });

          const trendingData = await Promise.all(trendingDataPromises);
          setCarouselData(trendingData);
        };

        getTrendingData();
      })
      .catch((error) => {
        console.error("There was an error fetching the trending data!", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${apiUrl}/home`)
      .then((response) => {
        const cities = response.data.map((city) => {
          const cityInfo = cityData.find((c) => c.name === city.city);
          return {
            heading: city.city,
            subheading: cityInfo ? cityInfo.headline : "",
            imageUrl: cityInfo ? cityInfo.imageUrl : "",
            destination: `/discover`,
          };
        });
        setTrendingCities(cities);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the trending cities!",
          error
        );
      });
  }, []);

  return (
    <>
      <Header searchBar={true} />
      <Banner
        heading="MOQAH.PK"
        subheading="Thriving above event expectations"
        data={carouselData}
      />
      <ButtonList
        id="browse-by-cities"
        heading="Browse By Cities"
        buttons={buttons}
      />
      <CreateEventBanner />
      <TrendingCities data={trendingCities} />
      <Footer />
    </>
  );
};

export default Home;
