import React, { useEffect, useState, useRef } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useParams } from "react-router-dom";
import axios from "axios";
import EventDetailsBanner from "../components/EventDetailsBanner";
import EventDetailsMain from "../components/EventDetailsMain";
import LoadingBox from "../components/LoadingBox";

const formatEventNameForUrl = (name) => {
  return name?.toLowerCase().replace(/\s+/g, "-") || ""; // Safeguard for undefined names
};

const EventDetails = () => {
  const eventDetailsMainRef = useRef(null);
  const { eventName } = useParams(); // Extract eventName from the URL
  const [event, setEvent] = useState(null);
  // Use environment-specific API URL
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const U_eventName = formatEventNameForUrl(eventName);
        const response = await axios.get(
          `${apiUrl}/event-details/${U_eventName}`
        );
        const eventData = response.data;
        let parsedLinks = {};
        try {
          parsedLinks = JSON.parse(eventData.links);
        } catch (error) {
          console.error("Failed to parse links:", error);
        }

        const checkImageExists = (url) => {
          return new Promise((resolve) => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
          });
        };

        const isValidImage = await checkImageExists(eventData.adv_banner);
        const backgroundImage = isValidImage
          ? eventData.adv_banner
          : "../../Adv_imgs/moqah_bg.png";

        setEvent({ ...eventData, parsedLinks, backgroundImage });
      } catch (error) {
        console.error("Error fetching event details:", error);
      }
    };

    fetchEventDetails();
  }, [eventName]); // Update the dependency to eventName

  const formatDuration = (duration) => {
    const hours = Math.floor(duration);
    const minutes = Math.round((duration - hours) * 60);
    return `${hours}h ${minutes}min`;
  };

  return (
    <>
      <Header searchBar={false} />
      <div>
        {event ? (
          <>
            <EventDetailsBanner
              data={{
                backgroundImage: event.backgroundImage,
                heading: event.name,
                city: event.city,
                location: event.location,
                // date: `${event.date.getDate().toString().padStart(2, "0")}-${(
                //   event.date.getMonth() + 1
                // )
                //   .toString()
                //   .padStart(2, "0")}-${event.date.getFullYear()}`,
                date: event.date,
                time: event.time,
                buttonContent1: event.cta_button_text1,
                buttonLink1: event.cta_button_link1,
                buttonContent2: event.cta_button_text2,
                buttonLink2: event.cta_button_link2,
                venue: event.venue,
              }}
            />
            <EventDetailsMain
              id="event-details-main"
              ref={eventDetailsMainRef}
              data={{
                heading: event.name,
                location: event.location,
                ticket_price: Math.min(...Object.values(event.ticket_price)),
                date: event.date,
                time: event.time,
                summary: event.short_summary,
                description: event.details,
                duration: formatDuration(event.duration),
                audience: event.audience,
                venue: event.venue,
                tags: event.tags,
                organizerDetails: {
                  name: event.org_name,
                  contact: event.contact,
                  link: event.parsedLinks,
                },
              }}
            />
          </>
        ) : (
          <LoadingBox />
        )}
      </div>
      <Footer />
    </>
  );
};

export default EventDetails;
